import * as React from 'react'
import {Slot} from '@radix-ui/react-slot'
import {type VariantProps} from 'class-variance-authority'

import {cn} from '@/lib/utils'
import clsx from 'clsx'
import {buttonVariants} from '@/util/ui-variants'
import Icon from '@mdi/react'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  icon?: string
  iconSpin?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      type,
      icon,
      children,
      iconSpin,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    if (icon) {
      if (asChild) {
        console.error('Button does not support asChild with Icon')
      } else {
        children = (
          <>
            <Icon
              path={icon}
              className={clsx(
                size !== 'icon' &&
                  size !== 'icon-lg' &&
                  (size === 'sm' ? 'mr-1' : 'mr-2'),
                iconSpin && 'animate-spin'
              )}
              size={size === 'icon-lg' ? 1.5 : 1}
            />
            {children}
          </>
        )
      }
    }
    return (
      <Comp
        className={cn(buttonVariants({variant, size, className}))}
        ref={ref}
        type={type || 'button'}
        {...props}
      >
        {children}
      </Comp>
    )
  }
)
Button.displayName = 'Button'

export {Button}
