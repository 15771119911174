import {mdiLoading} from '@mdi/js'
import Icon from '@mdi/react'
import clsx from 'clsx'
import {useEffect, useState} from 'react'

type Props = {
  text?: string
  center?: boolean
}

export function LoadingSpinner({text, center}: Props) {
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(true)
    }, 100)
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return (
    <div
      className={clsx('flex items-center gap-x-2', center && 'justify-center')}
    >
      <div
        className={
          'transition-opacity duration-300 ' +
          (visible ? 'opacity-100' : 'opacity-0')
        }
      >
        <Icon
          path={mdiLoading}
          className="w-6 h-6 text-blue-500 animate-spin"
        />
      </div>
      {text && <span>{text}</span>}
    </div>
  )
}
