import {Header} from '@/components/Header'
import {PageContainer} from '@/components/PageContainer'
import {Button} from '@/components/ui/button'
import {Card, CardContent, CardHeader, CardTitle} from '@/components/ui/card'
import {
  ControlHomeAssistantSpeakerDocument,
  GetHomeAssistantSpeakerDocument,
  HomeAssistantSpeaker,
  HomeAssistantSpeakerUpdatedDocument,
  TurnHomeAssistantSceneOnDocument,
  UserRole
} from '@/gql/gen/graphql'
import {useToast} from '@/hooks/use-toast'
import {formatError} from '@/utils/formatting'
import {useMutation, useQuery, useSubscription} from '@apollo/client'
import {
  mdiLightbulbOn,
  mdiLightbulbOn50,
  mdiLightbulbOffOutline,
  mdiPlay,
  mdiPause,
  mdiVolumeMinus,
  mdiVolumePlus,
  mdiSkipNext,
  mdiSkipPrevious,
  mdiSpeakerOff,
  mdiLightbulbGroupOffOutline
} from '@mdi/js'
import {ErrorMessage} from '@/components/ErrorMessage'
import {LoadingSpinner} from '@/components/LoadingSpinner'
import {ThreeDot, ThreeDotItem} from '@/components/ThreeDot'
import Icon from '@mdi/react'
import {useViewer} from '@/hooks/useViewer'

export default function Home() {
  const viewer = useViewer()

  return (
    <PageContainer>
      <Header />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {viewer.roles.includes(UserRole.HomeAssistantOperator) && (
          <>
            <LightsCard />
            <SpeakerCard />
          </>
        )}
      </div>
    </PageContainer>
  )
}

function LightsCard() {
  const [turnHomeAssistantSceneOn] = useMutation(
    TurnHomeAssistantSceneOnDocument
  )
  const {toast} = useToast()

  async function turnSceneOn(sceneId: string) {
    try {
      await turnHomeAssistantSceneOn({
        variables: {
          input: {
            sceneId
          }
        }
      })
    } catch (e) {
      toast({variant: 'destructive', description: formatError(e)})
    }
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>Lights</CardTitle>
          <ThreeDot negativeMargin>
            <ThreeDotItem
              icon={mdiLightbulbGroupOffOutline}
              text={`Turn off all downstairs lights`}
              onClick={() => turnSceneOn('downstairs_light_off')}
            />
          </ThreeDot>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-wrap gap-2">
          <SceneButton
            sceneId="$area_light_off"
            icon={mdiLightbulbOffOutline}
          />
          <SceneButton sceneId="$area_low_light" icon={mdiLightbulbOn50} />
          <SceneButton sceneId="$area_full_light" icon={mdiLightbulbOn} />
        </div>
      </CardContent>
    </Card>
  )
}

function SceneButton({sceneId, icon}: {sceneId: string; icon: string}) {
  const [turnHomeAssistantSceneOn] = useMutation(
    TurnHomeAssistantSceneOnDocument
  )
  const {toast} = useToast()

  async function onClick() {
    try {
      await turnHomeAssistantSceneOn({
        variables: {
          input: {
            sceneId
          }
        }
      })
    } catch (e) {
      toast({variant: 'destructive', description: formatError(e)})
    }
  }

  return (
    <Button
      onClick={onClick}
      icon={icon}
      size="icon-lg"
      variant="ghost"
    ></Button>
  )
}

function SpeakerCard() {
  const res = useQuery(GetHomeAssistantSpeakerDocument)
  const speaker = res.data?.homeAssistantSpeaker
  useSubscription(HomeAssistantSpeakerUpdatedDocument)

  function localUpdate(changes: Partial<HomeAssistantSpeaker>) {
    res.updateQuery(prev => ({
      ...prev,
      homeAssistantSpeaker: prev.homeAssistantSpeaker && {
        ...prev.homeAssistantSpeaker,
        ...changes
      }
    }))
  }

  const [controlHomeAssistantSpeaker] = useMutation(
    ControlHomeAssistantSpeakerDocument
  )
  const {toast} = useToast()

  async function controlAction(action: string) {
    try {
      await controlHomeAssistantSpeaker({
        variables: {
          input: {
            action
          }
        }
      })
    } catch (e) {
      toast({variant: 'destructive', description: formatError(e)})
    }
  }

  function onPlay() {
    localUpdate({playing: true})
  }

  function onPause() {
    localUpdate({playing: false})
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>Speaker</CardTitle>
          <ThreeDot negativeMargin>
            <ThreeDotItem
              icon={mdiPlay}
              text={`Play "Now" playlist`}
              onClick={() => controlAction('play_now_playlist')}
            />
          </ThreeDot>
        </div>
      </CardHeader>
      <CardContent>
        {res.error ? (
          <ErrorMessage error={res.error} />
        ) : speaker == null ? (
          <LoadingSpinner center />
        ) : (
          <div className="space-y-3">
            <div className="flex gap-3">
              {speaker.mediaImageUrl ? (
                <img
                  src={speaker.mediaImageUrl}
                  className="rounded-sm w-12 h-12"
                />
              ) : (
                <div className="rounded-sm w-12 h-12 border flex items-center justify-center">
                  <Icon
                    path={mdiSpeakerOff}
                    size={1}
                    className="text-slate-500"
                  />
                </div>
              )}
              <div className="flex-1">
                {speaker.mediaTitle ? (
                  <div className="font-semibold truncate">
                    {speaker.mediaTitle}
                  </div>
                ) : (
                  <div className="truncate">Not playing</div>
                )}
                <div className="text-sm text-slate-500 truncate">
                  {speaker.mediaArtist}
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              <SpeakerButton action="previous_track" icon={mdiSkipPrevious} />
              {speaker.playing ? (
                <SpeakerButton
                  action="pause"
                  icon={mdiPause}
                  onClick={onPause}
                />
              ) : (
                <SpeakerButton action="play" icon={mdiPlay} onClick={onPlay} />
              )}
              <SpeakerButton action="next_track" icon={mdiSkipNext} />
              <div className="flex-1" />
              <SpeakerButton
                action="volume_down"
                icon={mdiVolumeMinus}
                disabled={speaker.volumeLevel == 0}
              />
              <SpeakerButton action="volume_up" icon={mdiVolumePlus} />
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

function SpeakerButton({
  action,
  icon,
  onClick: outerOnClick,
  disabled
}: {
  action: string
  icon: string
  onClick?: () => void
  disabled?: boolean
}) {
  const [controlHomeAssistantSpeaker] = useMutation(
    ControlHomeAssistantSpeakerDocument
  )
  const {toast} = useToast()

  async function onClick() {
    if (outerOnClick) {
      outerOnClick()
    }
    try {
      await controlHomeAssistantSpeaker({
        variables: {
          input: {
            action
          }
        }
      })
    } catch (e) {
      toast({variant: 'destructive', description: formatError(e)})
    }
  }

  return (
    <Button
      onClick={onClick}
      icon={icon}
      size="icon-lg"
      variant="ghost"
      disabled={disabled}
    ></Button>
  )
}
