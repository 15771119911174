import {createBrowserRouter} from 'react-router-dom'
import Root from '@/routes/Root'
import Home from '@/routes/Home'
import Settings from '@/routes/Settings'
import SignIn from '@/routes/SignIn'
import ErrorBoundary from '@/routes/ErrorBoundary'
import Authenticated from '@/routes/Authenticated'
import Unauthenticated from '@/routes/Unauthenticated'
import Users from '@/routes/Users'
import MySettings from '@/routes/MySettings'
import ShoppingList from '@/routes/ShoppingList'
import Gate from '@/routes/Gate'
import SignOut from '@/routes/SignOut'
import Vacation from './routes/Vacation'

export const router = createBrowserRouter([
  {
    path: '/',
    Component: Root,
    ErrorBoundary: ErrorBoundary,
    children: [
      {
        path: '',
        Component: Authenticated,
        children: [
          {
            path: 'home',
            Component: Home
          },
          {
            path: 'shopping-list',
            Component: ShoppingList
          },
          {
            path: 'gates/:gateId',
            Component: Gate
          },
          {
            path: 'settings',
            Component: Settings,
            children: [
              {
                path: 'users',
                Component: Users
              },
              {
                path: 'vacation',
                Component: Vacation
              },
              {
                path: 'my',
                Component: MySettings
              }
            ]
          }
        ]
      },
      {
        path: '/',
        Component: Unauthenticated,
        children: [
          {
            path: 'sign-in',
            Component: SignIn
          }
        ]
      }
    ]
  },
  {
    path: 'sign-out',
    Component: SignOut
  }
])
