import {UserRole} from '@/gql/gen/graphql'

export const allUserRoles = [
  UserRole.GateOperator,
  UserRole.HomeAssistantOperator,
  UserRole.Shopper,
  UserRole.UserManager,
  UserRole.VacationManager
]

export function formatUserRole(role: UserRole) {
  return role
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
