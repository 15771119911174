import {UserRole} from '@/gql/gen/graphql'
import {useViewer} from '@/hooks/useViewer'
import {isScoopKiosk} from '@/util/scoop-kiosk'
import {
  mdiAccountCircle,
  mdiAccountGroup,
  mdiAirplane,
  mdiChevronRight
} from '@mdi/js'
import Icon from '@mdi/react'
import {Link, NavLink, Outlet, useLocation} from 'react-router-dom'

interface SubmenuItem {
  text: string
  icon: string
  to: string
  role?: UserRole
}

const submenuItems: SubmenuItem[] = [
  {
    text: 'Users',
    icon: mdiAccountGroup,
    to: '/settings/users',
    role: UserRole.UserManager
  },
  {
    text: 'Vacation',
    icon: mdiAirplane,
    to: '/settings/vacation',
    role: UserRole.VacationManager
  },
  {
    text: 'My Settings',
    icon: mdiAccountCircle,
    to: '/settings/my'
  }
]

export default function Settings() {
  const viewer = useViewer()

  const isSettingsIndex = useLocation().pathname === '/settings'

  return (
    <div className="relative h-full overflow-hidden lg:flex">
      <nav
        className={
          `absolute z-0 inset-0 transition duration-300 flex flex-col p-3 pb-4 lg:static lg:w-60 lg:h-screen lg:border-r lg:border-gray-200 lg:translate-x-0 lg:opacity-100 ` +
          (isSettingsIndex ? 'translate-x-0' : '-translate-x-1/2 opacity-0')
        }
      >
        <ul role="list" className="flex flex-col flex-1 gap-y-1">
          {submenuItems
            .filter(
              item => item.role == null || viewer.roles.includes(item.role)
            )
            .map((item, idx) => (
              <li
                key={idx}
                className="border-b border-gray-200 lg:border-0 last:border-0"
              >
                <NavLink
                  to={item.to}
                  className={({isActive}) =>
                    'flex items-center p-2 gap-x-2 rounded-md ' +
                    (isActive
                      ? 'lg:text-primary lg:bg-primary-100'
                      : 'text-slate-800 lg:hover:bg-primary-50')
                  }
                >
                  <Icon path={item.icon} className="size-6" />
                  <div className="flex-1 truncate">{item.text}</div>
                  <Icon path={mdiChevronRight} className="size-6 lg:hidden" />
                </NavLink>
              </li>
            ))}
        </ul>

        <div className="text-center mb-2 text-slate-500">
          Signed in as <b>{viewer.username}</b>
        </div>
        {!isScoopKiosk() && (
          <div className="flex justify-center">
            <Link to="/sign-out">Sign out</Link>
          </div>
        )}
      </nav>
      <div
        id="submenu-main"
        className={
          `absolute z-10 inset-0 transition duration-300 bg-white overflow-y-auto lg:static lg:translate-x-0 lg:flex-1 ` +
          (isSettingsIndex ? 'translate-x-full' : 'translate-x-0')
        }
      >
        <Outlet />
      </div>
    </div>
  )
}
