import {cva} from 'class-variance-authority'

export const badgeVariants = cva(
  'inline-flex items-center rounded-full border border-slate-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-1 dark:border-slate-800 dark:focus:ring-slate-300',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-slate-900 text-slate-50 hover:bg-slate-900/80 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/80',
        secondary:
          'border-transparent bg-slate-100 text-slate-900 hover:bg-slate-100/80 dark:bg-slate-800 dark:text-slate-50 dark:hover:bg-slate-800/80',
        destructive:
          'border-transparent bg-red-500 text-slate-50 hover:bg-red-500/80 dark:bg-red-900 dark:text-slate-50 dark:hover:bg-red-900/80',
        outline: 'text-slate-950 dark:text-slate-50'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

export const buttonVariants = cva(
  'flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus focus-visible:ring-offset-1 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-blue-500 text-white hover:bg-blue-400 active:bg-blue-600',
        destructive: 'bg-red-500 text-slate-50 hover:bg-red-500/90',
        outline:
          'border border-slate-200 bg-white hover:bg-slate-100 hover:text-slate-900',
        secondary:
          'bg-white border border-blue-500 text-slate-900 hover:bg-hover active:bg-active',
        ghost:
          'text-blue-500 hover:bg-hover active:text-blue-600 active:bg-active',
        link: 'text-slate-900 underline-offset-4 hover:underline'
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-6 text-sm rounded-md px-2',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        'icon-lg': 'h-16 w-16'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)
