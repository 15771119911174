import {ErrorMessage} from '@/components/ErrorMessage'
import {Header} from '@/components/Header'
import {LoadingSpinner} from '@/components/LoadingSpinner'
import {PageContainer} from '@/components/PageContainer'
import {ThreeDot, ThreeDotItem} from '@/components/ThreeDot'
import {Button} from '@/components/ui/button'
import {Checkbox} from '@/components/ui/checkbox'
import {Input} from '@/components/ui/input'
import {Popover, PopoverAnchor, PopoverContent} from '@/components/ui/popover'
import {Separator} from '@/components/ui/separator'
import {
  CheckShoppingItemDocument,
  CreateShoppingItemDocument,
  InferShoppingItemSectionsDocument,
  ListShoppingItemAutocompleteSuggestionsDocument,
  ListShoppingItemsDocument,
  ShoppingItem,
  ShoppingItemUpdatedDocument,
  UncheckShoppingItemDocument,
  UpdateShoppingItemDocument
} from '@/gql/gen/graphql'
import {useToast} from '@/hooks/use-toast'
import {formatError} from '@/utils/formatting'
import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription
} from '@apollo/client'
import {mdiLoading, mdiPlus, mdiTag} from '@mdi/js'
import clsx from 'clsx'
import {useEffect, useMemo, useRef, useState} from 'react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

interface SectionWithItems {
  id: string
  name: string
  sortOrder: number
  items: ShoppingItem[]
}

export default function ShoppingList() {
  const {toast} = useToast()

  const res = useQuery(ListShoppingItemsDocument)
  useSubscription(ShoppingItemUpdatedDocument, {
    onData: ({data}) => {
      const item = data.data!.shoppingItemUpdated!
      if (
        res.data?.shoppingItems &&
        !res.data?.shoppingItems.find(i => i.id === item.id)
      ) {
        res.updateQuery(prev => {
          return {
            ...prev,
            shoppingItems: [...prev.shoppingItems, item]
          }
        })
      }
    }
  })

  const items = useMemo(() => {
    if (!res.data) {
      return null
    }
    const sectionMap: Record<string, SectionWithItems> = {
      uncategorized: {
        id: 'uncategorized',
        name: 'Uncategorized',
        sortOrder: -1,
        items: []
      }
    }
    for (const section of res.data.shoppingSections) {
      sectionMap[section.id] = {
        id: section.id,
        name: section.name,
        sortOrder: section.sortOrder || 0,
        items: []
      }
    }
    const checkedItems: ShoppingItem[] = []
    for (const item of res.data.shoppingItems) {
      if (item.checked) {
        checkedItems.push(item)
      } else {
        const sectionId = item.section?.id || 'uncategorized'
        const section = sectionMap[sectionId]
        if (!section) {
          console.warn(`Section ${sectionId} not found`)
          continue
        }
        section.items.push(item)
      }
    }
    const sections = Array.from(Object.values(sectionMap))
    sections.sort((a, b) => a.sortOrder - b.sortOrder)
    for (const section of sections) {
      section.items.sort((a, b) =>
        (b.checkedChangedAt || '').localeCompare(a.checkedChangedAt || '')
      )
    }
    checkedItems.sort((a, b) =>
      (b.checkedChangedAt || '').localeCompare(a.checkedChangedAt || '')
    )
    return {sections, checkedItems}
  }, [res.data])

  const onCreate = (item: ShoppingItem) => {
    res.updateQuery(prev => {
      return {
        ...prev,
        shoppingItems: [...prev.shoppingItems, item]
      }
    })
  }

  const [inferShoppingItemSections, {loading: inferLoading}] = useMutation(
    InferShoppingItemSectionsDocument
  )
  const inferSections = async () => {
    try {
      await inferShoppingItemSections()
      toast({variant: 'default', description: 'Items categorized.'})
    } catch (e) {
      toast({variant: 'destructive', description: formatError(e)})
    }
  }

  return (
    <PageContainer>
      <Header
        actions={
          <>
            {inferLoading && <LoadingSpinner text="Categorizing items..." />}
            <ThreeDot>
              <ThreeDotItem
                icon={mdiTag}
                text="Categorize items"
                onClick={inferSections}
              />
            </ThreeDot>
          </>
        }
      >
        Shopping List
      </Header>
      <div className="flex flex-col">
        <CreateForm onCreate={onCreate} />
        {res.error ? (
          <ErrorMessage error={res.error} />
        ) : !items ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {items.sections.map(section => (
              <Section key={section.id} section={section} />
            ))}
            <Separator
              className={clsx(
                'my-4',
                items.checkedItems.length === 0 && 'invisible'
              )}
            />
            <ItemGroup items={items.checkedItems} />
          </>
        )}
      </div>
    </PageContainer>
  )
}

interface CreateFormProps {
  onCreate: (item: ShoppingItem) => void
}

function CreateForm({onCreate}: CreateFormProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [text, setText] = useState('')
  const [createShoppingItem, {loading, error}] = useMutation(
    CreateShoppingItemDocument
  )

  const [autocompleteOpen, setAutocompleteOpen] = useState(false)
  const [autocompleteSelected, setAutocompleteSelected] = useState<
    number | null
  >(null)
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
    string[]
  >([])
  useQuery(ListShoppingItemAutocompleteSuggestionsDocument, {
    variables: {text},
    fetchPolicy: 'network-only',
    skip: text === '',
    onCompleted(data) {
      setAutocompleteSuggestions(data.shoppingItemAutocompleteSuggestions)
      setAutocompleteSelected(null)
    }
  })

  function handleOnChange(text: string) {
    setText(text)
    if (text !== '' && !autocompleteOpen) {
      setAutocompleteOpen(true)
      setAutocompleteSelected(null)
      setAutocompleteSuggestions([])
    } else if (text === '') {
      setAutocompleteOpen(false)
    }
  }

  async function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      if (autocompleteOpen && autocompleteSelected !== null) {
        create(autocompleteSuggestions[autocompleteSelected])
      } else {
        onSubmit()
      }
    } else if (e.key === 'Escape') {
      e.preventDefault()
      if (autocompleteOpen) {
        setAutocompleteOpen(false)
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      if (autocompleteOpen) {
        setAutocompleteSelected(prev =>
          prev === null || prev === 0 ? null : prev - 1
        )
      } else {
        // Just so that the cursor doesn't move to the start of the input
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault()
      if (autocompleteOpen) {
        setAutocompleteSelected(prev =>
          Math.min(
            autocompleteSuggestions.length - 1,
            prev === null ? 0 : prev + 1
          )
        )
      } else {
        const firstTextInput = document.querySelector(
          '[data-shopping-list-item] [data-shopping-list-item-column="text"]'
        ) as HTMLInputElement | null
        if (firstTextInput) {
          focusInput(firstTextInput)
        }
      }
    }
  }

  function onBlur() {
    setAutocompleteOpen(false)
  }

  async function onSubmit() {
    if (text === '') {
      return
    }
    create(text)
  }

  async function create(text: string) {
    const res = await createShoppingItem({variables: {input: {text}}})
    setText('')
    onCreate(res.data!.createShoppingItem!.result!)
    inputRef.current?.focus()
    setAutocompleteOpen(false)
  }

  return (
    <div className="mb-4">
      <div className="relative flex gap-x-2">
        <div className="flex-1">
          <Popover
            open={autocompleteOpen && autocompleteSuggestions.length > 0}
          >
            <PopoverAnchor asChild>
              <Input
                ref={inputRef}
                value={text}
                onChange={e => handleOnChange(e.target.value)}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                readOnly={loading}
                placeholder="Add item..."
                data-shopping-list-add-input
              />
            </PopoverAnchor>
            <PopoverContent
              onOpenAutoFocus={e => e.preventDefault()}
              style={{width: 'var(--radix-popover-trigger-width)'}}
              className="p-2"
            >
              {autocompleteSuggestions.map((option, idx) => (
                <div
                  key={idx}
                  className={clsx(
                    'px-2 py-1 rounded',
                    idx === autocompleteSelected && 'bg-active'
                  )}
                  onMouseMove={() => setAutocompleteSelected(idx)}
                  // Make sure that the input doesn't lose focus
                  onMouseDown={e => e.preventDefault()}
                  onClick={() => create(option)}
                >
                  {option}
                </div>
              ))}
            </PopoverContent>
          </Popover>
        </div>
        {loading ? (
          <Button size="icon" icon={mdiLoading} iconSpin disabled />
        ) : (
          <Button onClick={onSubmit} size="icon" icon={mdiPlus} />
        )}
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  )
}

interface SectionProps {
  section: SectionWithItems
}

function Section({section}: SectionProps) {
  const nameRef = useRef<HTMLDivElement>(null)

  return (
    <div className="shopping-list-section">
      <div
        ref={nameRef}
        className={clsx(
          'shopping-list-section-name',
          section.items.length > 0 && 'shopping-list-section-name-visible'
        )}
      >
        <div className="flex items-center gap-x-2 pt-2">
          <div className="text-sm font-medium">{section.name}</div>
        </div>
      </div>
      <ItemGroup items={section.items} />
    </div>
  )
}

interface ItemGroupProps {
  items: ShoppingItem[]
}

function ItemGroup({items}: ItemGroupProps) {
  return (
    <TransitionGroup>
      {items.map(item => (
        <ItemRow key={item.id} item={item} />
      ))}
    </TransitionGroup>
  )
}

interface ItemRowProps {
  item: ShoppingItem
}

function ItemRow({item, ...rest}: ItemRowProps) {
  const client = useApolloClient()
  const {toast} = useToast()
  const nodeRef = useRef<HTMLDivElement>(null)

  // Checking/unchecking
  const [checkShoppingItem] = useMutation(CheckShoppingItemDocument)
  const [uncheckShoppingItem] = useMutation(UncheckShoppingItemDocument)
  async function onCheckedChange(checked: boolean) {
    try {
      if (checked) {
        await checkShoppingItem({
          variables: {id: item.id},
          optimisticResponse: {
            checkShoppingItem: {
              __typename: 'CheckShoppingItemResult',
              result: {
                ...item,
                checked: true,
                checkedChangedAt: new Date().toISOString()
              }
            }
          }
        })
      } else {
        await uncheckShoppingItem({
          variables: {id: item.id},
          optimisticResponse: {
            uncheckShoppingItem: {
              __typename: 'UncheckShoppingItemResult',
              result: {
                ...item,
                checked: false,
                checkedChangedAt: new Date().toISOString()
              }
            }
          }
        })
      }
    } catch (e) {
      toast({variant: 'destructive', description: formatError(e)})
    }
    // Move focus to next/previous item
    if (nodeRef.current) {
      if (!focusNextItem(nodeRef.current, 'checkbox', true)) {
        focusNextItem(nodeRef.current, 'checkbox', false)
      }
    }
  }

  // State to track saving the text
  const state = useRef<{
    clientText: string
    serverText: string
    hasFocus: boolean
    submitTimeout?: number
    submitting?: boolean
  }>({
    clientText: item.text,
    serverText: item.text,
    hasFocus: false
  })
  // This is the current value of the text input
  const [clientText, setClientText] = useState(item.text)
  // Submits the latest client text to the server
  async function submitText() {
    state.current.submitting = true
    try {
      const textToSubmit = state.current.clientText
      await client.mutate({
        mutation: UpdateShoppingItemDocument,
        variables: {id: item.id, input: {text: textToSubmit}}
      })
      state.current.serverText = textToSubmit
    } catch (e) {
      // Revert client text to server text
      setClientText(state.current.serverText)
      state.current.clientText = state.current.serverText
      // Show error toast
      toast({variant: 'destructive', description: formatError(e)})
    } finally {
      state.current.submitting = false
    }
    // Check if we should update again?
    scheduleSubmitText()
  }
  // Debounces updates to the server (unless if already submitting or text
  // hasn't changed)
  function scheduleSubmitText(delay = 250) {
    // Do nothing if the mutation is already running. Once it's done, it'll
    // check if the client text is still different, and if so, run again
    if (state.current.submitting) {
      return
    }
    // Do nothing if the text is the same
    if (state.current.clientText === state.current.serverText) {
      return
    }
    // Debounce updates to the server
    window.clearTimeout(state.current.submitTimeout)
    if (state.current.clientText !== '') {
      state.current.submitTimeout = window.setTimeout(submitText, delay)
    }
  }
  // When the user changes the input
  function onClientTextChange(text: string) {
    // Reflect the change in the input immediately
    setClientText(text)
    state.current.clientText = text
    // Schedule submit
    scheduleSubmitText()
  }
  // Keep track of whether the text input is focused so we know whether to sync
  // updates from the server to the input
  function onTextFocus() {
    state.current.hasFocus = true
  }
  function onTextBlur() {
    state.current.hasFocus = false
    if (state.current.clientText === '') {
      onClientTextChange(state.current.serverText)
    } else {
      scheduleSubmitText(0)
    }
  }
  // If the text changes from the backend, then sync it to the input
  useEffect(() => {
    // But don't if the input already has focus and the text is different
    if (
      state.current.hasFocus &&
      state.current.clientText !== state.current.serverText
    ) {
      return
    }
    setClientText(item.text)
    state.current.clientText = item.text
  }, [item.text])

  // Arrow navigation
  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault()
      const column =
        (e.target as HTMLElement).dataset.shoppingListItemColumn || ''
      const reverse = e.key === 'ArrowUp'
      if (!focusNextItem(nodeRef.current!, column, reverse)) {
        if (e.key === 'ArrowUp') {
          const addInput = document.querySelector(
            '[data-shopping-list-add-input]'
          ) as HTMLInputElement | null
          if (addInput) {
            focusInput(addInput)
          }
        }
      }
    }
  }

  return (
    <CSSTransition
      nodeRef={nodeRef}
      timeout={300}
      classNames="shopping-list-item"
      {...rest}
    >
      <div
        ref={nodeRef}
        className="shopping-list-item"
        data-item-id={item.id}
        onKeyDown={onKeyDown}
        data-shopping-list-item
      >
        <div className="flex gap-x-2 py-0.5 pl-1">
          <div className="h-6 flex items-center">
            <Checkbox
              checked={item.checked}
              onCheckedChange={onCheckedChange}
              data-shopping-list-item-column="checkbox"
            />
          </div>
          <div className="flex-1">
            <input
              type="text"
              className={clsx(
                'w-full bg-transparent rounded-none border-b border-transparent -mb-px focus:outline-none focus:border-b-blue-500 focus:text-blue-500',
                item.checked && 'line-through text-gray-500 focus:no-underline'
              )}
              value={clientText}
              onChange={e => onClientTextChange(e.target.value)}
              onFocus={onTextFocus}
              onBlur={onTextBlur}
              data-shopping-list-item-column="text"
            />
          </div>
          {/* <div className="w-64">{item.section?.name || 'Uncategorized'}</div> */}
        </div>
      </div>
    </CSSTransition>
  )
}

function focusNextItem(
  itemEl: Element,
  column: string,
  reverse = false
): boolean {
  const nextRow = findNextQuerySelector(
    itemEl,
    '[data-shopping-list-item]',
    reverse
  )
  if (nextRow) {
    const input = nextRow.querySelector(
      `[data-shopping-list-item-column="${column}"]`
    ) as HTMLInputElement | null
    if (input) {
      focusInput(input)
      return true
    }
  }
  return false
}

function focusInput(inputEl: HTMLInputElement) {
  inputEl.scrollIntoView({behavior: 'smooth', block: 'nearest'})
  inputEl.focus()
  inputEl.selectionStart = inputEl.selectionEnd = inputEl.value.length
}

function findNextQuerySelector(
  startEl: Element,
  selector: string,
  reverse = false
) {
  let cur: Element | null = startEl
  while (true) {
    if (reverse) {
      if (cur.previousElementSibling) {
        cur = cur.previousElementSibling
      } else {
        cur = cur.parentElement?.previousElementSibling || null
      }
    } else {
      if (cur.nextElementSibling) {
        cur = cur.nextElementSibling
      } else {
        cur = cur.parentElement?.nextElementSibling || null
      }
    }
    if (!cur) {
      return null
    }
    if (cur.matches(selector)) {
      return cur
    }
    let descendant: Element | null
    if (reverse) {
      const descendants = cur.querySelectorAll(selector)
      descendant = descendants[descendants.length - 1]
    } else {
      descendant = cur.querySelector(selector)
    }
    if (descendant) {
      return descendant
    }
  }
}
