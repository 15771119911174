import {shouldUseViewTransition} from '@/utils/view-transition'
import {mdiChevronLeft} from '@mdi/js'
import Icon from '@mdi/react'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'

interface Props {
  center?: boolean
  backTo?: string
  actions?: React.ReactNode
  children?: React.ReactNode
}

export function Header({children, center, backTo, actions}: Props) {
  const location = useLocation()

  if (!children) {
    return <div className="mb-3" />
  }

  return (
    <div className="mb-4 flex items-center gap-3 h-10">
      {backTo && (
        <Link
          to={backTo}
          className="flex items-center justify-center lg:hidden"
          unstable_viewTransition={shouldUseViewTransition(
            location.pathname,
            backTo
          )}
        >
          <Icon path={mdiChevronLeft} className="block size-6" />
        </Link>
      )}
      <h1
        className={clsx(
          'flex-1 text-xl font-semibold leading-8',
          center && 'text-center'
        )}
      >
        {children}
      </h1>
      {actions && <div className="flex items-center gap-2">{actions}</div>}
    </div>
  )
}
