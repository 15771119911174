import {Header} from '@/components/Header'
import {SubmenuPageContainer} from '@/components/SubmenuPageContainer'
import {
  GetVacationSettingsDocument,
  UpdateVacationSettingsDocument
} from '@/gql/gen/graphql'
import {useMutation, useQuery} from '@apollo/client'
import {useState} from 'react'
import {Card, CardContent} from '@/components/ui/card'
import {Label} from '@/components/ui/label'
import {Switch} from '@/components/ui/switch'
import {LoadingSpinner} from '@/components/LoadingSpinner'
import {ErrorMessage} from '@/components/ErrorMessage'

export default function Vacation() {
  const res = useQuery(GetVacationSettingsDocument)
  const vacationSettings = res.data?.vacationSettings

  const [working, setWorking] = useState(false)

  const [updateVacationSettings] = useMutation(UpdateVacationSettingsDocument)

  async function updateEnabled(enabled: boolean) {
    setWorking(true)
    try {
      await updateVacationSettings({variables: {input: {enabled}}})
    } finally {
      setWorking(false)
    }
  }

  return (
    <SubmenuPageContainer>
      <Header backTo="/settings">Vacation</Header>
      <Card>
        <CardContent>
          {res.error ? (
            <ErrorMessage error={res.error} />
          ) : vacationSettings == null ? (
            <LoadingSpinner />
          ) : (
            <div className="flex items-center space-x-2">
              <Switch
                id="airplane-mode"
                checked={vacationSettings.enabled}
                onCheckedChange={checked => updateEnabled(checked)}
                disabled={working}
              />
              <Label htmlFor="airplane-mode">Vacation mode</Label>
            </div>
          )}
        </CardContent>
      </Card>
    </SubmenuPageContainer>
  )
}
