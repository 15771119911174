import {Button} from './ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from './ui/dropdown-menu'
import {useState} from 'react'
import {cn} from '@/lib/utils'
import clsx from 'clsx'
import Icon from '@mdi/react'
import {mdiDotsVertical} from '@mdi/js'
import {useNavigate} from 'react-router-dom'

export function ThreeDot({
  negativeMargin,
  children
}: {
  negativeMargin?: boolean
  children: React.ReactNode
}) {
  const [open, setOpen] = useState(false)

  return (
    <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          size="icon"
          variant="ghost"
          className={clsx(
            negativeMargin && '-m-2',
            open && 'bg-active hover:bg-active'
          )}
        >
          <Icon path={mdiDotsVertical} className="w-6 h-6" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" sideOffset={0}>
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

interface ThreeDotItemProps {
  text: string
  icon?: string
  onClick?: () => void
  to?: string
  destructive?: boolean
}

export function ThreeDotItem({
  text,
  icon,
  onClick,
  to,
  destructive
}: ThreeDotItemProps) {
  const navigate = useNavigate()
  return (
    <DropdownMenuItem
      onClick={to ? () => navigate(to) : onClick}
      className={cn(destructive && 'text-red-500')}
    >
      {icon && <Icon path={icon} className="mr-2 h-4 w-4" size={1} />}
      <span>{text}</span>
    </DropdownMenuItem>
  )
}
